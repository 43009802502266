<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">成果汇交查看</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入地块名称" class="iw" ></el-input>
                <el-select v-model="zydw" placeholder="选择作业单位"  filterable  clearable class="iw">
                    <el-option v-for="item in zydwList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="ejdw" placeholder="选择二级单位"  filterable  clearable class="iw">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-select v-model="zdlx" placeholder="选择宗地类型"  filterable  clearable class="iw">
                    <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="fzdw" placeholder="选择发证地区"  filterable  clearable class="iw">
                        <el-option v-for="item in fzdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-input v-model="bdcdyh" placeholder="请输入不动产单元号" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-dropdown class="fr" style="margin-left:20px;">
                    <el-button type="warning">
                        导出pdf扫描件<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="exportPdfByZydw">按作业单位导出</el-dropdown-item>
                        <el-dropdown-item @click.native="exportPdfByEjdw">按二级单位导出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown class="fr">
                    <el-button type="warning">
                        导出Excel<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="exportExcelByZydw">按作业单位导出</el-dropdown-item>
                        <el-dropdown-item @click.native="exportExcelByEjdw">按二级单位导出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="LandName" label="宗地名称"></el-table-column>
                    <el-table-column prop="LandTypeName" label="宗地类型"></el-table-column>
                    <el-table-column prop="LandStatus" label="地块现状">
                        <template slot-scope="scope">
                            <span v-if="scope.row.LandStatus== '1'">正常</span>
                            <span v-else-if="scope.row.LandStatus== '2'">侵占</span>
                            <span v-else-if="scope.row.LandStatus== '3'">耕地</span>
                            <span v-else-if="scope.row.LandStatus== '4'">无井</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="ZSBH" label="证书编号"></el-table-column>
                    <el-table-column prop="QLR" label="权利人"></el-table-column>
                    <el-table-column prop="GYQK" label="共有情况"></el-table-column>
                    <el-table-column prop="ZL" label="坐落"></el-table-column>
                    <el-table-column prop="BDCDYH" label="不动产单元号"></el-table-column>
                    <el-table-column prop="QLLX" label="权利类型"></el-table-column>
                    <el-table-column prop="QLXZ" label="权利性质"></el-table-column>
                    <el-table-column prop="YT" label="用途"></el-table-column>
                    <el-table-column prop="MJ" label="证书面积（㎡）"></el-table-column>
                    <el-table-column prop="TxtMJ" label="实测面积（㎡）"></el-table-column>
                    <el-table-column prop="SYQX" label="使用期限"></el-table-column>
                    <el-table-column prop="QLQTZK" label="权利其他状况"></el-table-column>
                    <el-table-column prop="FZDW" label="发证地区"></el-table-column>
                    <el-table-column prop="TXT" label="坐标范围文件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TXT !=''" @click="downloadFile(scope.row.TXT)" style="color:#409EFF;cursor:pointer;">点击查看</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column>
                    <el-table-column prop="PDF" label="证书扫描件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.PDF !=''" @click="downloadFile(scope.row.PDF)" style="color:#409EFF;cursor:pointer;">点击查看</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column>
                    <el-table-column prop="CAD" label="CAD源文件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CAD !=''" @click="downloadFile(scope.row.CAD)" style="color:#409EFF;cursor:pointer;">点击下载</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column>

                    <el-table-column prop="SubmitUserName" label="提交人"></el-table-column>
                    
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <!-- <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delFruit(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button> -->
                            <el-button @click="positionPost(scope.row)" type="text" size="small" style="color:#E6A23C">查看宗地范围</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="exportName" :visible.sync="exportModal" width="900px" :close-on-click-modal="false" :top="exportType=='1'?'160px':'40px'">
            <el-form  status-icon  label-width="100px" class="demo-uForm">
                <el-form-item label="选择作业单位" v-show="exportType=='1'">
                    <el-checkbox-group v-model="checkUnits">
                        <el-checkbox v-for="item in zydwList" :label="item.SNOWID" :key="item.SNOWID">{{ item.DISPLAYNAME }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="选择二级单位" v-show="exportType=='2'">
                    <el-checkbox-group v-model="checkEjdws">
                        <el-checkbox v-for="item in ejdwList" :label="item" :key="item">{{ item }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>  
                    <p v-if="exportFileType=='2'" style="font-size:12px;color:#F56C6C;width:100%;float:left;">提示：导出pdf时可能会因为文件数量过多导致导出时间过慢请您耐心等待</p>
                    <el-button type="primary"  @click="checkExportUnits" >全选/全不选</el-button>
                    <el-button @click="exportModal = false" class="fr">取消</el-button>
                    <el-button :type="exportStatus?'danger':'warning'" @click="submitExport"  class="fr" style="margin-left:100px;">{{exportStatus?'文件导出中，点击停止':'开始导出'}}</el-button>
                    
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="fruitModal" width="800px" :close-on-click-modal="false" top="20px">
            <el-form :model="fruitInfo" status-icon :rules="fruitrules"  ref="fForm" label-width="200px" class="demo-uForm">
                <el-form-item label="地块名称">
                    <p>{{friuitLandName}}</p>
                </el-form-item>
                <el-form-item label="地块现状" prop="landStatus">
                    <el-select v-model="fruitInfo.landStatus" placeholder="请选择地块现状"  filterable  style="width:100%;">
                        <el-option value="1" label="正常"></el-option>
                        <el-option value="2" label="侵占"></el-option>
                        <el-option value="3" label="耕地"></el-option>
                        <el-option value="4" label="无井"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw">
                    <el-select v-model="fruitInfo.ejdw" placeholder="请选择二级单位"  filterable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="不动产证书编号" prop="zsbh">
                    <el-input v-model="fruitInfo.zsbh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利人" prop="qlr">
                    <el-input v-model="fruitInfo.qlr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="共有情况" prop="gyqk">
                    <el-input v-model="fruitInfo.gyqk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="坐落" prop="zl">
                    <el-input v-model="fruitInfo.zl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fruitInfo.bdcdyh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利类型" prop="qllx">
                    <el-input v-model="fruitInfo.qllx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利性质" prop="qlxz">
                    <el-input v-model="fruitInfo.qlxz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用途" prop="yt">
                    <el-input v-model="fruitInfo.yt" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="mj">
                    <el-input v-model="fruitInfo.mj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="使用期限" prop="syqx">
                    <el-input v-model="fruitInfo.syqx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利其他状况">
                    <el-input v-model="fruitInfo.qlqtzk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发证地区" prop="fzdw">
                    <el-select v-model="fruitInfo.fzdw" placeholder="请选择发证地区"  filterable allow-create style="width:100%;">
                        <el-option v-for="item in fzdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证书扫描件上传" prop="smj">
                    <el-upload
                        class="avatar-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :http-request="uploadFunC"
                        :before-upload="beforeAvatarUploadC">
                        <span v-if="smjFileName!= ''" style="color:#409EFF;">{{smjFileName}}</span>
                            <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="smjFileName!= ''" type="danger" size="mini" @click="deleteFileC()">取消选择</el-button>
                </el-form-item>
                <el-form-item label="CAD源文件上传">
                    <el-upload
                        class="avatar-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :http-request="uploadFunD"
                        :before-upload="beforeAvatarUploadD">
                        <span v-if="cadFileName!=''" style="color:#409EFF;">{{cadFileName}}</span>
                            <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="cadFileName!=''" type="danger" size="mini" @click="deleteFileD()">取消选择</el-button>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitFruitForm()">提交</el-button>
                    <el-button @click="fruitModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="查看宗地范围" :visible.sync="showMap" width="800px" :close-on-click-modal="false" top="20px">
            <div style="width:760px;height:400px;" id="mapboxB"></div>
        </el-dialog>
    </div>
</template>
<script type="text/javascript" src="../../public/statics/js/proj4.js"></script>
<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import ImageWMS from "ol/source/ImageWMS";
import OSM from 'ol/source/OSM';
import Projection from 'ol/proj/Projection';
import {Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer ,Image as ImageLayer} from "ol/layer";
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import { Fill, Stroke, Circle, Style } from "ol/style";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {register} from 'ol/proj/proj4';
import * as olProj from 'ol/proj';
import * as XLSX from 'xlsx';
export default {
  name: "RegionManage",
  data() {
    var landStatus = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择地块现状"));
      } else {
        callback();
      }
    };

    var ejdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var qlr = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利人"));
      } else {
        callback();
      }
    };

    var gyqk = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入共有情况"));
      } else {
        callback();
      }
    };

    var zl = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入坐落"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入不动产单元号"));
      } else {
        callback();
      }
    };

    var qllx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利类型"));
      } else {
        callback();
      }
    };

    var qlxz = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利性质"));
      } else {
        callback();
      }
    };

    var yt = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入用途"));
      } else {
        callback();
      }
    };
    
    var mJ_M=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };
    var syqx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用期限"));
      } else {
        callback();
      }
    };

    var fzdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入发证单位"));
      } else {
        callback();
      }
    };


    var smj=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传证书扫描件"));
      } else {
        callback();
      }
    };

    var zsbh=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入土地证（不动产证）号"));
      } else {
        callback();
      }
    };

    return {
        canDo:true,
        showPagination:false,
        ejdw:"",
        fzdw:"",
        name:"",
        zydw:"",
        zdlx:"",
        bdcdyh:"",

        

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        zdlxList:[],
        ejdwList:[],
        zydwList:[],
        ejzydwList:[],
        fzdwList:[],
        fruitModal:false,
        fruitInfo:{},
        fruitrules: {
            landStatus: [{ validator: landStatus, trigger: "blur" }],
            ejdw: [{ validator: ejdw, trigger: "blur" }],
            zsbh: [{ validator: zsbh, trigger: "blur" }],
            qlr: [{ validator: qlr, trigger: "blur" }],
            gyqk: [{ validator: gyqk, trigger: "blur" }],
            zl: [{ validator: zl, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            qllx: [{ validator: qllx, trigger: "blur" }],
            qlxz: [{ validator: qlxz, trigger: "blur" }],
            yt: [{ validator: yt, trigger: "blur" }],
            mj: [{ validator: mJ_M, trigger: "blur" }],
            syqx: [{ validator: syqx, trigger: "blur" }],
            fzdw: [{ validator: fzdw, trigger: "blur" }],
            smj:[{ validator: smj, trigger: "blur" }],
        },
        friuitLandName:"",

        smjFile:null,
        smjFileSrc:"",
        smjFileId:"",
        smjFileName:"",
        chooseSmjFile:false,
        cadFile:null,
        cadFileSrc:"",
        cadFileId:"",
        cadFileName:"",
        chooseCadFile:false,



        drawSource:null,
        drawVector:null,
        map:null,
        view:null,
        showMap:false,
        txtArea:0,

        exportModal:false,
        exportName:"",
        exportType:"",
        exportFileType:"",
        checkUnits:[],
        checkEjdws:[],
        exportTimer:"",
        exportStatus:false,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.landName
        this.ejdw=getQueryData.ejdw
        this.fzdw=getQueryData.fzdw
    }
    this.loadItem=null
    this.getPostList()
    this.getEjdwList()
    this.getFzdwList()
    this.getUnitList()
    this.getZdlx()
  },
  methods: {
    getZdlx(){
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
            })
    },
    getUnitList(){
        this.$http.post("WorkUnit/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list=this.sortArrayNewList(response.data.DATA)
                    for(var i=0;i<list.length;i++){
                        var dw=list[i]
                        if(dw !=undefined){
                            if(dw.DWJB==1){
                                this.zydwList.push(dw)
                            }else if(dw.DWJB==2){
                                this.ejzydwList.push(dw)
                            }
                        }
                        
                    }
                }
            })
    },
    sortArrayNewList(list){
        var arr = new Array(list.length);
        for(var i=0;i<list.length;i++){
            var name=list[i].DISPLAYNAME
            if(name.indexOf("辽宁地质勘查")!= -1){
                arr[0]=list[i]
            }else if(name.indexOf("无锡")!= -1){
                arr[1]=list[i]
            }else if(name.indexOf("南京地正")!= -1){
                arr[2]=list[i]
            }else if(name.indexOf("山东")!= -1){
                arr[3]=list[i]
            }else if(name.indexOf("北京")!= -1){
                arr[4]=list[i]
            }else if(name.indexOf("中地云")!= -1){
                arr[5]=list[i]
            }else if(name.indexOf("盘锦恒信")!= -1){
                arr[6]=list[i]
            }else if(name.indexOf("盘锦北阳")!= -1){
                arr[7]=list[i]
            }else if(name.indexOf("大连")!= -1){
                arr[8]=list[i]
            }else if(name.indexOf("盘锦宏源")!= -1){
                arr[9]=list[i]
            }else if(name.indexOf("盘锦恒岳")!= -1){
                arr[10]=list[i]
            }else if(name.indexOf("安徽")!= -1){
                arr[11]=list[i]
            }else if(name.indexOf("辽宁地矿")!= -1){
                arr[12]=list[i]
            }else if(name.indexOf("核工")!= -1){
                arr[13]=list[i]
            }else if(name.indexOf("盘锦荣泰")!= -1){
                arr[14]=list[i]
            }
        }
        return arr
    },
    getParentUnitName(item){
        var parentdwName=""
        var nowName=item.WorkUnitName
        for(var i=0;i<this.ejzydwList.length;i++){
            if(this.ejzydwList[i].DISPLAYNAME == nowName){
                var pid=this.ejzydwList[i].ParentSnowID
                for(var j=0;j<this.zydwList.length;j++){
                    if(this.zydwList[j].SNOWID == pid){
                        parentdwName=this.zydwList[j].DISPLAYNAME
                        break;
                    }
                }
                break;
            }
        }
        return parentdwName
    },
    downloadFile(src){
        window.open(src)
    },
    getFzdwList(){
        this.$http.post("ExchangeResult/GetDistrictList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fzdwList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            ejdw:this.ejdw,
            fzdw:this.fzdw,
            landName:this.name,
            workUnitSnowID:this.zydw,
            landTypeSnowID:this.zdlx,
            LZBDCDYH:this.bdcdyh,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("ExchangeResult/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var nl=[]
                    for(var i=0;i<response.data.DATA.length;i++){
                        var d=response.data.DATA[i]
                        d.CAD=""
                        d.PDF=""
                        d.TXT=''
                        d.WorkUnitName=this.getParentUnitName(d)
                        for(var j=0;j<d.Files.length;j++){
                            var path=this.comjs.replaceFileSrc(d.Files[j].Path)
                            if(d.Files[j].ImageType==2){
                                d.PDF=path
                            }else if(d.Files[j].ImageType==3){
                                d.CAD=path
                            }else if(d.Files[j].ImageType==7){
                                d.TXT=path
                            }
                        }
                        nl.push(d)
                    }
                    this.postList=nl
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    editPost(item){
        this.smjFile=null
        this.smjFileSrc=""
        this.smjFileId=""
        this.smjFileName=""
        this.chooseSmjFile=false

        this.cadFile=null
        this.cadFileSrc=""
        this.cadFileId=""
        this.cadFileName=""
        this.chooseCadFile=false

        this.friuitLandName=item.DISPLAYNAME
        this.doTypeStr=item.DISPLAYNAME+"成果汇交登记"

        var  smj=""
        for(var i=0;i<item.Files.length;i++){
            if(item.Files[i].ImageType ==2){
                smj="1"
                this.smjFileName="证书扫描件.pdf"
                this.smjFileSrc=item.Files[i].Path
                this.smjFileId=item.Files[i].SnowID
            }else if(item.Files[i].ImageType ==3){
                this.cadFileName="CAD源文件.dwg"
                this.cadFileSrc=item.Files[i].Path
                this.cadFileId=item.Files[i].SnowID
            }
        }
        this.fruitInfo={
            SnowID:item.SnowID,
            landSnowID:item.LandSnowID,
            landStatus:item.LandStatus,
            submitUserSnowID:this.$store.state.snowid,
            ejdw:item.EJDW,
            zsbh:item.ZSBH,
            qlr:item.QLR,
            gyqk:item.GYQK,
            zl:item.ZL,
            bdcdyh:item.BDCDYH,
            qllx:item.QLLX,
            qlxz:item.QLXZ,
            yt:item.YT,
            mj:item.MJ,
            syqx:item.SYQX,
            qlqtzk:item.QLQTZK,
            fzdw:item.FZDW,
            smj:smj
        }
        this.doType="2"
        this.fruitModal=true
    },
    submitFruitForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    delete  this.fruitInfo.smj
                    this.sendFEdit()
                } else {
                    return false;
                }
            });
        }
    },
    sendFEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/UpdateData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已提交")
                    this.fruitModal=false
                    this.getPostList()
                    if(this.chooseSmjFile){
                        this.addImgC(this.fruitInfo.SnowID)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(this.fruitInfo.SnowID)
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    delFruit(item){
        if(this.canDo){
            this.$confirm('确定删除地块'+item.LandName+'的成果汇交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDelFruit(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelFruit(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.fruitModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    beforeAvatarUploadC(file) {
        const isJPG = file.type === 'application/pdf';
        if (!isJPG) {
        this.$message.error('证书扫描件只能是 PDF  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.smjFileSrc = _URL.createObjectURL(file);
            this.chooseSmjFile=true
            this.smjFile = file
            this.smjFileName=file.name
            this.fruitInfo.smj="1"
        }
        return isJPG;
    },
    uploadFunC(file){
        //console.log(file)
    },
    beforeAvatarUploadD(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('CAD源文件件只能是 DWG  格式!');
        }else{
            if(file.name.split(".")[1] !="dwg"){
                this.$message.error('CAD源文件件只能是 DWG  格式!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.cadFileSrc = _URL.createObjectURL(file);
                this.chooseCadFile=true
                this.cadFile = file
                this.cadFileName=file.name
            }
        }
        return isJPG;
    },
    uploadFunD(file){
        //console.log(file)
    },
    deleteFileC(){
        this.smjFile =null
        this.smjFileSrc=""
        this.smjFileName=""
        this.chooseSmjFile=false
        this.fruitInfo.smj=""

        if( this.smjFileId!=""){
            this.sendDelFile(this.smjFileId)
        }
    },
    deleteFileD(){
        this.cadFile =null
        this.cadFileSrc=""
        this.cadFileName=""
        this.chooseCadFile=false
        if( this.cadFileId!=""){
            this.sendDelFile(this.cadFileId)
        }
    },
    sendDelFile(id){
        this.$http.post("File/DeleteFile", {SnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },

    addImgC(id){
        var fd = new FormData();
        fd.append("file", this.smjFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=2&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    addImgD(id){
        var fd = new FormData();
        fd.append("file", this.cadFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=3&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },


    positionPost(item){
        console.log(item)
        this.getTxtFileContent(item.TXT,'mapboxB')
    },
    getTxtFileContent(src,mapId){
        //http://localhost:8080/statics/lib/txtTemplate.txt
        //src
        // this.$http.get("file/GetContent?fileURL="+src, {})
        //this.$getFileAxios.get(src, {})
        var srcA='http://localhost:8080/statics/lib/txtTemplate.txt'
        this.$http.get("file/GetContent?fileURL="+src, {})
            .then((response) => {
               var arr=this.getZbByFileStr(response.data);
                if(arr.length>0){
                    this.showMap=true
                    setTimeout(()=>{
                        this.loadMapDoms(arr,mapId)
                    },500)
                }else{
                    this.$message.error('文件坐标格式有误')
                }
            })
    },
    transformXY(olist){
        var arr={
            a:[],
            b:[],
        }
        for(var k=0;k<olist.length;k++){
            var list =olist[k]
            var l={a:[],b:[],}
            for(var i=0;i<list.length;i++){
                var arritem=[list[i][1],list[i][0]]
                l.a.push(arritem)
                var lonlat_3857 = olProj.transform(arritem, 'EPSG:4529','EPSG:3857');
                l.b.push(lonlat_3857)
            }
            arr.a.push(l.a)
            arr.b.push(l.b)
        }
        
        return arr
    },
    getZbByFileStr(str){
        var arrs=[]
        var zbstr=str.replace(/\s+/g, '')
        var zbs=[]
        if(zbstr.indexOf("Z")!= -1){
            zbs= zbstr.split("Z")
        }else{
            zbs.push(zbstr)
        }

        for(var i=0;i<zbs.length;i++){
            var mian=zbs[i]
            var mianArr=[]
            if(mian.indexOf("J")!=-1){
                var dianArr=mian.split("J")
                for(var j=0;j<dianArr.length;j++){
                    var dian=dianArr[j]
                    var dianDatas=[]
                    if(dian!=""&&dian.indexOf(",")!=-1){
                        var diansZbs=dian.split(",")
                        for(var k=0;k<diansZbs.length;k++){
                            var dianZbItem=diansZbs[k]
                            if(dianZbItem.length>=7 || dianZbItem.indexOf(".")!=-1){
                                dianDatas.push(parseFloat(dianZbItem))
                            }
                        }
                        if(dianDatas.length==2){
                            mianArr.push(dianDatas)
                        }
                    } 
                }
            }
            if(mianArr.length>=4){
                if(mianArr[0][0] != mianArr[mianArr.length-1][0] || mianArr[0][1] != mianArr[mianArr.length-1][1]){
                    mianArr.push(mianArr[0])
                }
                arrs.push(mianArr)
            }
        }
        return arrs
    },
    loadMapDoms(arr,mapId){
        if(this.map){
            this.map.getLayers().forEach(function(layer) {
                layer.setMap(null);
            });
            this.map.getControls().forEach((control)=> {
                this.map.removeControl(control);
            });
        
            this.map.getInteractions().forEach((interaction)=> {
                this.map.removeInteraction(interaction);
            });
        
            this.map.setTarget(null);
            this.map = null;
        }
        proj4.defs(
                "EPSG:4529",
                "+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs"
            )
            register(proj4);
            var center_lonlat = [123.049,41.928];
            var lonlat_3857 = olProj.fromLonLat(center_lonlat, olProj.get("EPSG:3857"));
            this.view=new View({
                center: lonlat_3857, 
                projection: 'EPSG:3857',
                zoom: 15, 
            })
            this.map = new Map({
                zIndex:1,
                target: mapId,
                layers: [
					new ImageLayer({
						name:"背景", 
						source: new ImageWMS({
							url: "https://file.17fbfb.com/?map=/etc/mapserver/wms.map&service=wms?",
							params: {
								MAP:'/home/oil_field/conf/test1.map',
								LAYERS:'uav',
								//CRS:'epsp:4529',
								//BBOX:'122,41,123.2,42',
								transparent: "true"
							},
							serverType:'mapserver'
						}),
					}),
                    new ImageLayer({
						name:"地块", 
						source: new ImageWMS({
							url: "https://file.17fbfb.com/?map=/etc/mapserver/wms.map&service=wms?",
							params: {
								MAP:'/home/oil_field/conf/test1.map',
								LAYERS:'zongdi',
								//CRS:'epsp:4529',
								//BBOX:'122,41,123.2,42',
								transparent: "true"
							},
							serverType:'mapserver'
						}),
					}),
				],
                view: this.view,
            });
            this.map.on('singleclick', function(event) {
                const coordinate = event.coordinate; 
                //console.log(coordinate)
            })
            this.map.updateSize()
            this.drawSource = new VectorSource({wrapX: false});
            this.drawVector = new VectorLayer({
                source: this.drawSource,
                zIndex:100,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(64,158,255,0.2)"
                    }),
                    stroke: new Stroke({
                        color: "#409EFF",
                        width: 2
                    }),
                    image: new Circle({
                        radius: 5,
                        fill: new Fill({
                            color: "rgba(64,158,255,1)"
                        })
                    })
                }),
            });
            this.map.addLayer(this.drawVector)
            var arrItem=this.transformXY(arr)
            var areaGeom= new window.ol.geom.Polygon(arrItem.a)
            this.txtArea=Math.abs(areaGeom.getArea())
            var feaGeom= new Polygon(arrItem.b)
            var ft = new Feature({
                geometry: feaGeom,
            });
            this.drawSource.addFeature(ft)
            this.view.setCenter(arrItem.b[0][0])
            this.view.setZoom(15)
    },

    exportExcelByZydw(){
        this.exportName="按作业单位导出Excel"
        this.exportType="1"
        this.exportFileType="1"
        this.exportModal=true
    },
    exportExcelByEjdw(){
        this.exportName="按二级单位导出Excel"
        this.exportType="2"
        this.exportFileType="1"
        this.exportModal=true
    },
    exportPdfByZydw(){
        this.exportName="按作业单位导出pdf扫描件"
        this.exportType="1"
        this.exportFileType="2"
        this.exportModal=true
    },
    exportPdfByEjdw(){
        this.exportName="按二级单位导出pdf扫描件"
        this.exportType="2"
        this.exportFileType="2"
        this.exportModal=true
    },
    submitExport(){
        if(this.canDo){
            if(this.exportStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.exportStatus=false
                    this.exportTimer=new Date().getTime().toString()
                })
               
            }else{
                if(this.exportType=="1" && this.checkUnits.length===0){
                    this.$message.error("请至少选择一个作业单位")
                }else if(this.exportType=="2" && this.checkEjdws.length===0){
                    this.$message.error("请至少选择一个二级单位")
                }else{
                    console.log("ok")
                    this.exportTimer=new Date().getTime().toString()
                    if(this.exportFileType=="1"){
                        this.sendExportExcel(this.exportTimer)
                    }else {
                        this.sendExportPdf(this.exportTimer)
                    }
                    
                }
            }
            
        }

    },
    sendExportExcel(timestr){
        if(timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={}
            if(this.exportType=="1"){
                params.workUnitSnowIDList=this.checkUnits
                params.ejdwList=[]
            }else{
                params.ejdwList=this.checkEjdws
                params.workUnitSnowIDList=[]
            }
            this.$download.post("ExportExcel/ExportExchangeResultList", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName()
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.xlsx')
                                } else {
                                window.navigator.msSaveBlob(blob, namenew+".xlsx")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".xlsx"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                    this.canDo=true
                })
                .catch((error) => {
                    this.exportStatus=false
                    this.canDo=true
                    this.$message.error("数据导出失败")
                });
            }
    },
    sendExportPdf(timestr){
        if(timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={}
            if(this.exportType=="1"){
                params.workUnitSnowIDList=this.checkUnits
                params.ejdwList=[]
            }else{
                params.ejdwList=this.checkEjdws
                params.workUnitSnowIDList=[]
            }
            this.$download.post("ExportExcel/ExportResultFile", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName()
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.zip')
                                } else {
                                window.navigator.msSaveBlob(blob, namenew+".zip")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".zip"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                    this.canDo=true
                })
                .catch((error) => {
                    this.exportStatus=false
                    this.canDo=true
                    this.$message.error("数据导出失败")
                });
            }
    },
    getZydwStrById(id){
        var name=''
        for(var i=0;i<this.zydwList.length;i++){
            if(this.zydwList[i].SNOWID == id){
                name=this.zydwList[i].DISPLAYNAME
                break;
            }
        }
        return name
    },
    getFileName(){
        var name=""
        if(this.exportType="1"){
            for(var i=0;i<this.checkUnits.length;i++){
                var dwname=this.getZydwStrById(this.checkUnits[i])
                if(i==0){
                    name=dwname
                }else{
                    name+="_"+dwname
                }
            }
        }else{
            for(var j=0;j<this.checkEjdws.length;j++){
                var dwname=this.checkEjdws[j]
                if(j==0){
                    name=dwname
                }else{
                    name+="_"+dwname
                }
            }
        }
        
        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t=y.toString()+m.toString()+d.toString()
        name+="_"+t

        var fileType=""
       if(this.exportFileType=="1"){
        fileType="成果统计表"
       }else{
        fileType="证书扫描件"
       }
        name+=fileType
        return name
    },
    checkExportUnits(){
        var nl=[]
        if(this.exportType=="1"){
            if(this.checkUnits.length<this.zydwList.length){
                for(var i=0;i<this.zydwList.length;i++){
                    nl.push(this.zydwList[i].SNOWID)
                }
            }
            this.checkUnits=nl
        }else{
            if(this.checkEjdws.length<this.ejdwList.length){
                nl = this.ejdwList
            }
            this.checkEjdws=nl
        }
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}
.el-checkbox-group{line-height:30px;}
</style>