<template>
  <div id="app" class="appMain">
    <div class="appHead">
      <div class="logoBox" @click="toBigScreen">
          <!-- <img src="statics/img/logo.png" alt=""> -->
      </div>
      <div class="hisNavBox">
          <div class="hisNavBoxIn">
              <div class="hisNavItme" :class="nowCheckName == item?'hisNavItmeOn':''" v-for="item in historyList" :key="item" @click.stop="showNav(item)">
                  <p class="text">{{routerNameStr(item)}}</p>
                  <p v-show="historyList.length>1" class="close" @click.stop="hideNav(item)">×</p>
              </div>
          </div>
      </div>
      <div class="loginInfo">
        <img :src="headImg" class="headImg">
        <div class="ddBox">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="changePwdModal = true">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logoutModal = true">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
    </div>
    <div class="appLeft">
        <el-menu :default-active="defActive"  background-color="#545c64" text-color="#fff" :collapse="isCollapse" router active-text-color="#ffd04b">
            <!-- <el-menu-item index="/DataView">
              <i class="el-icon-s-data"></i>
              <span slot="title">首页总览</span>
            </el-menu-item> -->
            <el-submenu index="1">
                <template slot="title">
                  <i class="el-icon-s-custom"></i>
                  <span>角色管理</span>
                </template>  
                <el-menu-item index="/AdminUserManage">平台管理员</el-menu-item>
                <el-menu-item index="/UnitManage">作业单位管理</el-menu-item>
                <el-menu-item index="/WorkUserList">作业人员查看</el-menu-item>
                <el-menu-item index="/WorkUserMacList">人员新设备登录审核</el-menu-item>
                <!-- <el-menu-item index="/PositionManage">定位器管理</el-menu-item> -->
                <el-menu-item index="/OilUserManage">油田用户管理</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
                <template slot="title">
                  <i class="el-icon-coin"></i>
                  <span>基础数据管理</span>
                </template>
                <el-menu-item index="/RegionManage">采区管理</el-menu-item>
                <el-menu-item index="/LandManage">地块管理</el-menu-item>
                <el-menu-item index="/CardTypeManage">证书类型管理</el-menu-item>
                <el-menu-item index="/FallTypeManage">落宗土地用途管理</el-menu-item>
                <!-- <el-menu-item index="/LandTypeManage">成果地块类型管理</el-menu-item> -->
                <el-menu-item index="/CantonManage">行政区系数设置</el-menu-item>
                <el-menu-item index="/DegreeManage">难度级别设置</el-menu-item>
                <el-menu-item index="/DictionaryManage">字典项管理</el-menu-item>
                <!-- <el-menu-item index="/OilWellManage">井口管理</el-menu-item> -->
            </el-submenu>
            <el-submenu index="3">
                <template slot="title">
                  <i class="el-icon-s-order"></i>
                  <span>任务管理</span>
                </template>
                <el-menu-item index="/LandTaskManage">不动产调查任务</el-menu-item>   
                <el-menu-item index="/FallList">落宗明细表</el-menu-item>   
                <el-menu-item index="/FruitManage">成果汇交</el-menu-item>
                <el-menu-item index="/OilWellTaskManage">井口范围调查任务</el-menu-item>   
            </el-submenu>
        </el-menu>
    </div>
    <div class="appRight" v-for="item in historyList" :key="item" v-show="nowCheckName == item">
        <router-view :name="item"></router-view>
    </div>
    <el-dialog title="操作提示" :visible.sync="logoutModal" width="30%">
      <span>确定退出登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutModal = false">取 消</el-button>
        <el-button type="primary" @click="logoutSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePwdModal">
      <el-form :model="changePwd" status-icon :rules="pwdrules" ref="cpForm" label-width="100px" class="demo-cpForm">
        <el-form-item label="原密码" prop="oldPass">
          <el-input type="password" v-model="changePwd.oldPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input type="password" v-model="changePwd.newPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="changePwd.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPwdForm('cpForm')">提交</el-button>
          <el-button @click="changePwdModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    var validatePass0 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入原密码'));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.changePwd.checkPass !== '') {
          this.$refs.cpForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.changePwd.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      sysType:"",

      isCollapse:false,
      loadpage:false,
      defActive:"",
      userName:"",
      headImg:"statics/img/headImg.png",


      logoutModal:false,
      changePwdModal:false,
      changePwd: {
          oldPass:'',
          newPass: '',
          checkPass: '',
      },
      pwdrules: {
        oldPass: [
          { validator: validatePass0, trigger: 'blur' }
        ],
        newPass: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      canDo:true,
      menuList:[],


      historyList:[],
      nowCheckName:"",
      routerNameStr:function(name){
        var str=""
        if(name == "DataView"){
          str="首页总览"
        }else if(name == "AdminUserManage"){
          str="平台管理员"
        }else if(name == "PositionManage"){
          str="定位器管理"
        }else if(name == "UnitManage"){
          str="作业单位管理"
        }else if(name == "WorkUserList"){
          str="作业人员查看"
        }else if(name == "OilUserManage"){
          str="油田用户管理"
        }else if(name == "LandManage"){
          str="地块管理"
        }else if(name == "LandTypeManage"){
          str="成果地块类型管理"
        }else if(name == "FallTypeManage"){
          str="落宗土地用途管理"
        }else if(name == "CantonManage"){
          str="行政区系数设置"
        }else if(name == "DegreeManage"){
          str="难度级别设置"
        }else if(name == "CardTypeManage"){
          str="证书类型管理"
        }else if(name == "FruitManage"){
          str="成果汇交"
        }else if(name == "OilWellManage"){
          str="井口管理"
        }else if(name == "LandTaskManage"){
          str="不动产调查任务"
        }else if(name == "OilWellTaskManage"){
          str="井口范围调查任务"
        }else if(name == "RegionManage"){
          str="采区管理"
        }else if(name == "WorkUserMacList"){
          str="人员新设备登录审核"
        }else if(name == "FallList"){
          str="落宗明细表"
        }else if(name == "DictionaryManage"){
          str="字典项管理"
        }
        return str
      }

    };
  },
  mounted() {
    this.sysType=this.$store.state.sysType
    this.loadItem=null
    this.isLoginPage(this.$route.name)
  },
  methods: {
    isLoginPage(name){
      this.userName=this.$store.state.name
      this.defActive='/'+name
      this.setHistoryRouters(name)
      this.loadpage=true
    },
    //退出登录
    logoutSend(){
      this.logoutModal=false
      this.logout()
    },
    //修改密码
    submitPwdForm(formName) {
      if (this.canDo) {
          this.$refs.cpForm.validate((valid) => {
              if (valid) {
                this.sendChangePwdA()
              } else {
                  return false;
              }
          });
      }
    },
    sendChangePwdA() {
       var params={
        sysUserSnowID:this.$store.state.snowid,
        oldPassWord:this.changePwd.oldPass,
        newPassWord:this.changePwd.newPass,
       }
       this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("SysUser/UpdatePassWord", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.changePwdModal=false
                    this.$message.success("已修改")
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                    if(error.response.status==401){
                      this.logout()
                    }
                }
             });
    },
    toBigScreen(){
      // var today=this.comjs.getDateMinStrByDate(new Date)
      // window.localStorage.setItem("lhytdp_token", this.$store.state.token);
      // window.localStorage.setItem("lhytdp_snowid", this.$store.state.guid);
      // window.localStorage.setItem("lhytdp_loginname", this.$store.state.loginName);
      // window.localStorage.setItem("lhytdp_turename", this.$store.state.name);
      // window.localStorage.setItem("lhytdp_logintime", today);
      var url=window.location.host;
      window.open("http://"+url+"/statics/bigScreen/index.html")
    },
    setHistoryRouters(name){
      this.nowCheckName=name
      if(this.historyList.length ==0){
          this.historyList.push(name)
      }else{
          var has=false
          for(var i=0;i<this.historyList.length;i++){
            if(this.historyList[i] === name){
              has=true
              break;
            }
          }
          if(!has){
            this.historyList.push(name)
          }
      }
    },
    showNav(name){
      if(this.nowCheckName!=name){
        this.$router.replace("/"+name);
      }
    },
    hideNav(name){
        if(this.historyList.length>1){
            var nl=[]
            var next=""
            for(var i=0;i<this.historyList.length;i++){
                if(this.historyList[i]!= name){
                  nl.push(this.historyList[i])
                }else{
                    if(i>0){
                        next = this.historyList[i-1]
                    }else{
                        next = this.historyList[i+1]
                    }
                }
            }
            this.historyList=nl
            if(this.nowCheckName==name){
                this.$router.replace("/"+next);
            }
        }
    },
  },
  computed: {

  },
  watch: {
    '$route.name': function (newVal, oldVal) {
      this.setHistoryRouters(newVal)
      this.isLoginPage(newVal)
    }
  },
};
</script>
 
<style>

.appMain{width: 100%;height: 100%;position: relative;overflow: hidden;}

.appHead{height: 56px;position: absolute;left: 0px;top: 0px;right: 0px;background-color: #FFFFFF;}
.appLeft{width: 240px;position: absolute;left: 0px;top: 56px;bottom: 0px;background-color: #545c64;}
.appLeft .el-menu::-webkit-scrollbar { width: 0 !important }
.appRight{position: absolute;left: 240px;bottom: 0px;right: 0px;top: 56px;overflow: auto;background-color: #F8F8F8;border-radius: 4px;}



.logoBox{height: 55px;width: 240px;float:left;background-color: #545c64;overflow: hidden;border-bottom: 1px solid transparent;cursor: pointer;}
.logoBox img{width: 144px;display: block;float:left;margin-top: 5px;margin-left: 10px;}

.loginInfo{overflow: hidden;height: 56px;position: absolute;right: 20px;top: 0;}
.loginInfo .headImg{width: 36px;float: left;margin-top: 10px;}
.appHead .headCon{height: 56px;float:left;overflow: hidden;}
.appHead .headCon .btnItem{float:left;margin-right: 20px;}
.loginInfo .ddBox{float:left;margin-left: 16px;cursor: pointer;margin-top: 15px;}
.appMain .maxpage{left: 0;right: 0;top: 0;bottom: 0;overflow: hidden;padding: 0;}
.el-menu{border-right: 0!important;    max-height: 100%;overflow-y: auto;overflow-x: hidden;}
/* .el-menu-item:hover{background-color: #FFFFFF!important;} */


.el-dialog{overflow: hidden;}
.pgItem{margin-bottom: 20px;}
.taskItemBox .el-dialog{overflow: hidden;}

.hisNavBox{height: 55px;overflow: hidden;max-width: calc(100% - 500px);}
.hisNavBoxIn{width: 100%;overflow-x: scroll;overflow-y: hidden;height: 75px;}
.hisNavItme{height: 32px;border: 1px solid #ECECEC; border-radius: 3px;float:left;margin-left: 10px;margin-top: 11px;cursor: pointer;padding:0 10px;}
.hisNavItme .text{height: 32px; color: #333;font-size: 12px;text-align: center;line-height: 32px;float:left;}
.hisNavItme .close{font-size: 20px;margin-top: 2px;color: #ADADAD;float:left;margin-left: 6px;}

.hisNavItmeOn {border: 1px solid #409EFF;}
.hisNavItmeOn .text{color:#409EFF;}
</style>
