<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">不动产调查任务</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入任务名称" class="iwu" ></el-input>
                <el-select v-model="status" placeholder="选择任务状态"  filterable  clearable class="iwu">
                        <el-option label="待分配至作业单位" value="0" ></el-option>
                        <el-option label="待分配至作业人员" value="1" ></el-option>
                        <el-option label="作业人员待提交" value="2" ></el-option>
                        <el-option label="正常提交" value="3" ></el-option>
                        <el-option label="异常标注" value="4" ></el-option>
                </el-select>
                <el-input v-model="landName" placeholder="请输入地块名称" class="iwu" ></el-input>
                
                <!-- <el-select v-model="landId" placeholder="选择地块"  filterable  clearable class="iw">
                        <el-option v-for="item in landList" :key="item.SNOWID" :label="item.ZSBH" :value="item.SNOWID" ></el-option>
                </el-select> -->
                <el-select v-model="zydw" placeholder="选择作业单位"  filterable  clearable class="iw" @change="changezydw">
                    <el-option v-for="item in unitList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <!-- <el-input v-model="unitName" placeholder="请输入作业单位名称" class="iwu" ></el-input> -->
                <el-input v-model="userName" placeholder="请输入作业人员名称" class="iwu" ></el-input>
                <el-select v-model="ejdw" placeholder="选择二级单位"  filterable  clearable  allow-create class="iwu">
                    <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-select v-model="fallType" placeholder="是否落宗"  filterable  clearable class="iwu">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select>
                <!-- <el-date-picker v-model="giveTime" type="date" placeholder="选择分配日期" class="iw"></el-date-picker>
                <el-date-picker v-model="submitTime" type="date" placeholder="选择提交日期" class="iw"></el-date-picker> -->
                <el-button type="primary" @click="searchPost">查询</el-button>

                <el-dropdown class="fr" style="margin-left:20px;">
                    <el-button :type="downFileStatus?'danger':'warning'" >
                        {{downFileStatus?'点击停止导出':'导出任务'}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="exportExcel">按查询条件导出</el-dropdown-item>
                        <el-dropdown-item @click.native="exportNormalExcel">按作业单位导出落宗汇总</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>



                <!-- <el-button type="success" @click="addPost" class="fr">一键生成任务</el-button> -->
                <!-- <el-button type="primary" @click="calcelGive" class="fr">撤销分配</el-button> -->
                <el-dropdown class="fr">
                    <el-button type="warning">
                        任务分配<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="taskGiveByEjdw">二级单位分配</el-dropdown-item>
                        <el-dropdown-item @click.native="taskGiveByList">勾选列表分配</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="60" :selectable="setCanSelect"></el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="ZSBH" label="地块证书编号"></el-table-column>
                    <el-table-column prop="MJ_M" label="面积（亩）"></el-table-column>
                    <el-table-column prop="LandName" label="宗地项目名称"></el-table-column>
                    <el-table-column prop="STATUS" label="任务状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== 0">待分配至作业单位</span>
                            <span v-else-if="scope.row.STATUS== 1">待分配至作业人员</span>
                            <span v-else-if="scope.row.STATUS== 2">作业人员待提交</span>
                            <span v-else-if="scope.row.STATUS== 3">正常提交</span>
                            <span v-else-if="scope.row.STATUS== 4">异常标注</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="GIVETIME" label="分配时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!= 0">{{scope.row.GIVETIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WorkUnitName" label="作业单位">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!= 0">{{scope.row.WorkUnitName}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WorkUserName" label="作业人员">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!= 0 && scope.row.STATUS!= 1">{{scope.row.WorkUserName}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                   
                    <el-table-column prop="LASTSUBMITTIME" label="最近一次提交时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== 3||scope.row.STATUS== 4">{{scope.row.LASTSUBMITTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SCMJ" label="实测面积（亩）">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SCMJ">{{scope.row.SCMJ}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MJBYZSM" label="调查备注">
                        <template slot-scope="scope">
                            <span v-if="scope.row.MJBYZSM">{{scope.row.MJBYZSM}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ErrorInfo" label="异常标注原因">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== 4">{{scope.row.ErrorInfo}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="showTask(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS== 3 || scope.row.STATUS== 4">查看提交情况</el-button>
                            <el-button @click="editUnit(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="scope.row.STATUS== 1">修改作业单位</el-button>
                            <el-button @click="cancelGive(scope.row)" type="text" size="small" style="color:#F56C6C" v-if="scope.row.STATUS== 1">取消分配</el-button>
                            <el-button @click="cancelGive(scope.row)" type="text" size="small" style="color:#F56C6C" v-if="scope.row.STATUS== 2">取消分配</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small" style="color:#F56C6C">删除任务</el-button>
                            <el-button @click="showPost(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.IsHaveLZ && scope.row.IsHaveLZ==1">落宗详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="任务分配" :visible.sync="giveModal" width="800px" :close-on-click-modal="false">
            <el-form :model="giveInfo" status-icon :rules="giverules"  ref="gForm" label-width="100px" class="demo-uForm">
                <el-form-item label="二级单位" prop="ejdw" v-show="giveType=='2'">
                    <el-select v-model="giveInfo.ejdw" placeholder="请选择二级单位"  filterable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="作业单位" prop="userSnowID">
                    <el-select v-model="giveInfo.userSnowID" placeholder="请选择作业单位"  filterable  style="width:100%;">
                        <el-option v-for="item in unitList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitGiveForm()">提交</el-button>
                    <el-button @click="giveModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="修改作业单位" :visible.sync="unitModal" width="800px" :close-on-click-modal="false">
            <el-form :model="unitInfo" status-icon :rules="unitrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="作业单位" prop="workunitsnowid">
                    <el-select v-model="unitInfo.workunitsnowid" placeholder="请选择作业单位"  filterable  style="width:100%;">
                        <el-option v-for="item in unitList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitUnitForm()">提交</el-button>
                    <el-button @click="unitModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="submitName" :visible.sync="submitModal" width="800px" :close-on-click-modal="false">
            <el-timeline>
                <el-timeline-item  v-for="item in submitLogs"  :key="item.SNOWID" :timestamp="item.CREATETIME" placement="top">
                <el-card>
                    <p>提交人：{{item.CREATORNAME}}　　提交状态：<span v-if="item.STATUS ==3">正常提交</span><span v-else-if="item.STATUS ==4">异常标注</span></p>
                    <div class="taskLogimgBox" v-if="item.Files.length>0">
                        <el-image  v-for="imgs in item.Files"  :key="imgs.SNOWID"
                            style="width: 100px; height: 100px;margin-right:12px"
                            :src="imgs.Path" 
                            :preview-src-list="item.pimgs">
                        </el-image>
                    </div>
                </el-card>
                </el-timeline-item>
            </el-timeline>
        </el-dialog>
        <el-dialog :title="fallListTitle" :visible.sync="fallListModal" width="1300px" :close-on-click-modal="false" top="50px">
            <div style="overflow:hidden">
                <el-input v-model="sbdcdyh" placeholder="请输入不动产单元号" class="ics" ></el-input>
                <el-select v-model="zdlx" placeholder="选择宗地类型"  filterable  clearable class="ics">
                        <el-option v-for="item in FzdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-cascader v-model="regions"  :options="options" :props="cityPropsB"  placeholder="请选择省市区"  class="ics" clearable  filterable> </el-cascader>
                <!-- <el-select v-model="tjr" placeholder="选择提交人"  filterable  clearable class="ics" v-show="sysType=='2'">
                        <el-option v-for="item in tjrList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select> -->
                <el-select v-model="sfyz" placeholder="是否有证（批复）"  filterable  clearable class="ics">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select>
                <el-select v-model="mjsfyy" placeholder="面积是否一样"  filterable  clearable class="ics">
                        <el-option label="是" value="0" ></el-option>
                        <el-option label="否" value="1" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchFallList">查询</el-button>
            </div>
            <el-table :data="fallList" stripe style="width: 100%" :height="fallTableHeight"> 
                <el-table-column prop="LZLandTypeName" label="宗地类型"></el-table-column>
                <el-table-column prop="REGION" label="行政区"></el-table-column>
                <el-table-column prop="BDCDYH" label="不动产单元号" width="150"></el-table-column>
                <el-table-column prop="ISHAVECERTIFICATE" label="有无证（批复）" width="120" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.ISHAVECERTIFICATE== 1">有</span>
                        <span v-else>无</span>
                    </template>
                </el-table-column>
                <el-table-column prop="OLDBH" label="原证书编号" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.OLDBH">{{scope.row.OLDBH}}</span>
                        <span v-else>/</span>
                    </template>
                </el-table-column>
                <el-table-column prop="LZMJ" label="落宗面积"></el-table-column>
                <el-table-column prop="OLDMJ" label="原面积">
                    <template slot-scope="scope">
                        <span v-if="scope.row.OLDMJ">{{scope.row.OLDMJ}}</span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column prop="CHANGEINFO" label="面积变化原因" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.CHANGEINFO">{{scope.row.CHANGEINFO}}</span>
                        <span v-else>/</span>
                    </template>
                </el-table-column>

                <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                <el-table-column prop="WorkUserName" label="提交人"></el-table-column>
                <el-table-column prop="UpdateTime" label="提交时间"></el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next"  :current-page="FPageIndex" :page-size="FPageSize" :total="FTotal" class="pg" @current-change="FPGChange"></el-pagination>
            <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
    </div>
</template>
<script>
var h = document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var userSnowID = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输选择作业单位"));
      } else {
        callback();
      }
    };

    var ejdw=(rule, value, callback) => {
      if (this.giveType=="2" && value === "" || value == null) {
        callback(new Error("请输选择二级单位"));
      } else {
        callback();
      }
    };

    var workunitsnowid = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输选择作业单位"));
      } else {
        callback();
      }
    };


    return {
        
        canDo:true,
        showPagination:false,
        name:"",
        status:"",
        giveTime:null,
        submitTime:null,
        unitName:"",
        userName:"",
        ejdw:"",
        fallType:"",


        zydw:"",

        landList:[],
        landId:"",
        landName:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {},

        unitList:[],
        ejzydwList:[],

        ejdwList:[],

        giveType:"1",
        giveModal:false,
        giveInfo:{},
        giverules: {
            userSnowID: [{ validator: userSnowID, trigger: "blur" }],
            ejdw:[{ validator: ejdw, trigger: "blur" }],
        },
        selectIds:[],


        unitModal:false,
        unitInfo:{},
        unitrules: {
            workunitsnowid: [{ validator: workunitsnowid, trigger: "blur" }],
        },

        submitName:"",
        submitModal:false,
        submitLogs:[],

        downFileStatus:false,

        fallDetail:null,
        fallDetailModal:null,

        options:[],
        cityPropsB:{
            value:'name',   
            label:'name',  
            children: 'children',
            checkStrictly:true,
        },

        taskInfo:null,
        fallTableHeight:0,
        sbdcdyh:"",
        tjr:"",
        tjrList:[],
        sfyz:"",
        mjsfyy:"",
        zdlx:"",
        regions:[],
        FPageIndex:1,
        FPageSize:10,
        FTotal:0,   
        fallList:[],
        fallListModal:false,
        fallListTitle:"",
        FzdlxList:[],
    };
  },
  mounted() {
    this.fallTableHeight = h - 280
    this.options=this.comjs.citys
    this.loadItem=null
    this.getUnitList()
    this.getEjdwList()
    this.getPostList()
    this.getFZdlx()
  },
  methods: {
    getFZdlx(){
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.FzdlxList=response.data.DATA
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getUnitList(){
        this.$http.post("WorkUnit/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        if(d.DWJB==1){
                            this.unitList.push(d)
                        }else if(d.DWJB==2){
                            this.ejzydwList.push(d)
                        }
                    }
                }
            })
    },
    changezydw(e){
        this.unitName=""
        if(e!=''){
            for(var i=0;i<this.unitList.length;i++){
                if(e==this.unitList[i].SNOWID){
                    this.unitName=this.unitList[i].DISPLAYNAME
                }
            }
        }
    },


    //设置是否可选择分配
    setCanSelect(row,rowIndex){
        if(row.STATUS=="0"){
            return true
        }else{
            return false
        }
    },
    handleSelectionChange(val){
        this.selectIds=[]
        for(var i=0;i<val.length;i++){
            this.selectIds.push(val[i].SNOWID)
        }
    },
    taskGiveByList(){
        if(this.canDo){
            if(this.selectIds.length>0){
                this.giveInfo={
                    ejdw:"",
                    userSnowID:"",
                    taskSnowIDList:this.selectIds
                }
                this.giveType="1"
                this.giveModal=true
            }else{
                this.$message.error("请选择需要分配的任务")
            }
        }
    },

    taskGiveByEjdw(){
        this.giveInfo={
            ejdw:"",
            userSnowID:"",
            taskSnowIDList:[]
        }
        this.giveType="2"
        this.giveModal=true
    },


    submitGiveForm() {
        if (this.canDo) {
            this.$refs.gForm.validate((valid) => {
                if (valid) { 
                    if(this.giveType=="1"){
                        this.sendCreateTask()
                    }else{
                        this.giveTaskForEjdw()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    //
    sendCreateTask(){
        this.canDo=false
        this.showLoading("请稍后")
        var params=[
            {
                taskSnowIDList:this.giveInfo.taskSnowIDList,
                userSnowID:this.giveInfo.userSnowID
            }
        ]
        this.$http.post("Task/GiveTaskToWorkUnit", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("分配完成")
                    this.getPostList()
                    this.giveModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true 
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                }  
             });
    },
    giveTaskForEjdw(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            ejdw: this.giveInfo.ejdw,
            workUnitSnowID:this.giveInfo.userSnowID,
            type:"2",
        }
        this.$http.post("Task/GiveEJDWTaskToUnit", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    //this.$message.success("分配完成")
                    this.getPostList()
                    this.giveModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    //修改作业单位
    editUnit(item){
        this.unitInfo={
            snowid:item.SNOWID,
            workunitsnowid:item.WorkUnitSnowID,
            workusersnowid:"",
        }
        this.unitModal=true
    },
    submitUnitForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.sendChangeUnit()
                } else {
                    return false;
                }
            });
        }
    },
    sendChangeUnit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Task/UpdateData", this.unitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("操作完成")
                    this.getPostList()
                    this.unitModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    //获取任务
    getPostList(){
        this.showLoading("请稍后")
        var gst=""
        var get=""
        if(this.giveTime){
            gst=this.comjs.getDateMinStrByDate(this.giveTime)+" 00:00:00"
            get=this.comjs.getDateMinStrByDate(this.giveTime)+" 23:59:59"
        }

        var sst=""
        var set=""
        if(this.submitTime){
            sst=this.comjs.getDateMinStrByDate(this.submitTime)+" 00:00:00"
            sst=this.comjs.getDateMinStrByDate(this.submitTime)+" 23:59:59"
        }

        var dwjb=""
        // if(this.zydw !=""){
        //     dwjb="1" 
        // }

        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            name:this.name,
            stutas:this.status,
            //workUnitName:this.unitName,
            workUserName:this.userName,
            landName:this.landName,
            giveStartTime:gst,
            giveEndTime:get,
            LastSubmitStartTime:sst,
            LastSubmitEndTime:set,
            type:"2",
            landSnowID:this.landId,
            EJDW:this.ejdw,
            ISHAVELZ:this.fallType,
            DWJB:dwjb,
            WorkUnitSnowID:this.zydw,
        }
        this.$http.post("Task/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    var list=response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        // var pdwname=this.getParentUnitName(d)
                        // if(pdwname!=""){
                        //     d.WorkUnitName=pdwname
                        // }
                        nl.push(d)
                    }
                    this.postList=nl
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
    getParentUnitName(item){
        var parentdwName=""
        var nowName=item.WorkUnitName
        for(var i=0;i<this.ejzydwList.length;i++){
            if(this.ejzydwList[i].DISPLAYNAME == nowName){
                var pid=this.ejzydwList[i].ParentSnowID
                for(var j=0;j<this.unitList.length;j++){
                    if(this.unitList[j].SNOWID == pid){
                        parentdwName=this.unitList[j].DISPLAYNAME
                        break;
                    }
                }
                break;
            }
        }
        return parentdwName
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    addPost(){
        if(this.canDo){
            this.sendAdd()
        }
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Task/OneCreateLandTask", {})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("生成完成")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    cancelGive(item){
        if(this.canDo){
            this.$confirm('确定取消分配？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendCancelGive(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")    
                });
        }
    },
    sendCancelGive(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Task/RemokeTask", [id])
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已取消")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除任务：'+item.NAME+'？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")    
                });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Task/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },

    showTask(item){
        if(this.canDo){
            this.submitName=item.NAME+"提交记录"
            this.getTaskSubmitLogs(item.SNOWID)
        }
    },
    getTaskSubmitLogs(taskId){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("TaskSubmit/GetSubmitTaskDetailList", {taskSnowID:taskId})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        d.pimgs=[]
                        for(var j=0;j<d.Files.length;j++){
                            d.pimgs.push(d.Files[j].Path)
                        }
                        nl.push(d)
                    }
                    this.submitLogs=nl
                    this.submitModal=true
                } else {
                    this.$message.error("暂无数据")
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
             });
        
    },
    exportExcel(){
        if(this.canDo){
            if(this.downFileStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.downFileStatus=false
                })
            }else{
                this.$confirm('导出前可以根据查询条件进行筛选，确定执行导出吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendExportExcel()
                })
            }
            
        }
    },

    exportNormalExcel(){
        if(this.canDo){
            if(this.downFileStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.downFileStatus=false
                })
            }else{
                if(this.zydw==""){
                    this.$message.error("请选择作业单位")
                }else{
                    this.sendExportNormalExcel()
                }
            }
            
        }
    },
    sendExportNormalExcel(){
        this.downFileStatus=true
        var params={
            WorkUnitSnowID:this.zydw
        }
        this.$download.post("ExportExcel/ExportTaskLzResult", params,{ responseType: 'blob',timeout:300000})
            .then((resJson) => {
                if(this.downFileStatus){
                    if (resJson) {
                        var namenew=this.getNormalFileName()
                        let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                        if (window.navigator.msSaveOrOpenBlob) {
                            if (this.form.name) {
                                window.navigator.msSaveBlob(blob, this.form.name + '.xls')
                            } else {
                            window.navigator.msSaveBlob(blob, namenew+".xls")
                            }
                        } else {
                            let downloadElement = document.createElement('a')
                            let href = window.URL.createObjectURL(blob) 
                            downloadElement.href = href
                            downloadElement.download = namenew+".xls"
                            document.body.appendChild(downloadElement)
                            let evt = document.createEvent('MouseEvents')
                            evt.initEvent('click', false, false)
                            downloadElement.dispatchEvent(evt)
                            document.body.removeChild(downloadElement)
                        }
                        this.$message.success('文件已导出!')
                    } else {
                        this.$message.error("数据导出失败")
                    }
                }
                this.downFileStatus=false
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.downFileStatus=false
                this.hideLoading()
            });
    },
    sendExportExcel(){
        this.downFileStatus=true
        var params={
            name:this.name,
            stutas:this.status,
            //workUnitName:this.unitName,
            workUnitSNowID:this.zydw,
            workUserName:this.userName,
            landName:this.landName,
            EJDW:this.ejdw,
            type:"2",
        }
        this.$download.post("ExportExcel/ExportTaskList", params,{ responseType: 'blob',timeout:300000})
            .then((resJson) => {
                if(this.downFileStatus){
                    if (resJson) {
                        var namenew=this.getFileName()
                        let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                        if (window.navigator.msSaveOrOpenBlob) {
                            if (this.form.name) {
                                window.navigator.msSaveBlob(blob, this.form.name + '.xls')
                            } else {
                            window.navigator.msSaveBlob(blob, namenew+".xls")
                            }
                        } else {
                            let downloadElement = document.createElement('a')
                            let href = window.URL.createObjectURL(blob) 
                            downloadElement.href = href
                            downloadElement.download = namenew+".xls"
                            document.body.appendChild(downloadElement)
                            let evt = document.createEvent('MouseEvents')
                            evt.initEvent('click', false, false)
                            downloadElement.dispatchEvent(evt)
                            document.body.removeChild(downloadElement)
                        }
                        this.$message.success('文件已导出!')
                    } else {
                        this.$message.error("数据导出失败")
                    }
                }
                this.downFileStatus=false
            })
            .catch((error) => {
                this.hideLoading()
            });
    },
    getFileName(){
        var name=""
        if(this.name!=""){
            name+=this.name+"_"
        }
        if(this.landName!=""){
            name+=this.landName+"_"
        }
        if(this.unitName!=""){
            name+=this.unitName+"_"
        }
        if(this.userName!=""){
            name+=this.userName+"_"
        }
        if(this.ejdw!=""){
            name+=this.ejdw+"_"
        }

        if(this.status!=""){
            if(this.status=="0" || this.status=="1"){
                name+="待分配"+"_"
            }else if(this.status=="2"){
                name+="待提交"+"_"
            }else if(this.status=="3"){
                name+="正常提交"+"_"
            }else if(this.status=="4"){
                name+="异常标注"+"_"
            }
        }

        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t='任务清单_'+y.toString()+m.toString()+d.toString()
        name+=t
        return name
    },
    getNormalFileName(){
        var filename=''
        var unitname=""
        for(var i=0;i<this.unitList.length;i++){
            if(this.zydw == this.unitList[i].SNOWID){
                unitname=this.unitList[i].DISPLAYNAME
                break;
            }
        }
        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        filename=unitname+'正常提交'+m+d
        return filename
    },
    //显示落宗记录表
    showPost(item){
        this.taskInfo=item
        this.fallListTitle=item.NAME+"-落宗记录表"
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=1
        this.FTotal=0
        this.sbdcdyh=""
        this.tjr=""
        this.sfyz=""
        this.mjsfyy=""
        this.zdlx=""
        this.searchFallListFun()
        this.fallListModal=true
    },
    searchFallList(){
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=1
        this.FTotal=0
        this.searchFallListFun()
    },
    FPGChange(e){
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=e
        this.FTotal=0
        this.searchFallListFun()
    },
    searchFallListFun(){
        this.showLoading("请稍后")
        var region=''
        if(this.regions && this.regions.length>0){
            for(var i=0;i<this.regions.length;i++){
                if(i==0){
                    region=this.regions[i]
                }else{
                    region=region+"/"+this.regions[i]
                }
            }
        }
        var params={
            tasksnowid:this.taskInfo.SNOWID,
            BDCDYH:this.sbdcdyh,
            pageIndex:this.FPageIndex,
            pageSize:this.FPageSize,
            region:region,
            submitworkunitsnowid:this.zydwid,
            submitworkusersnowid:this.tjr,
            lzlandtypesnowid:this.zdlx,
            isHaveCertificate:this.sfyz,
            mjIsInconsistent:this.mjsfyy,
        }
        this.$http.post("LandLZ/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.fallList=response.data.DATA
                    this.FTotal=response.data.TOTALCOUNT
                } else {
                    this.fallList=[]
                    this.FTotal=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.fallList=[]
                this.FTotal=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

.taskLogimgBox{overflow:hidden;margin-top:20px;}
</style>